import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  PageNameComponent,
  BlankPageCardComponent,
  BackButtonComponent,
  AddButtonComponent,
  LoadingSpinnerComponent,
  ProgressBarComponent,
  AvatarComponent
} from './components';
import {
  PositionPipe,
  ShortNumberPipe,
  UnitPipe,
  ShortNamePipe,
  DateAgoPipe
} from './pipes';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';


@NgModule({
  declarations: [
    AddButtonComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    NgxSkeletonLoaderModule,

    ShortNamePipe,
    PositionPipe,
    ShortNumberPipe,
    DateAgoPipe,
    UnitPipe,

    AvatarComponent,
    ProgressBarComponent,
    LoadingSpinnerComponent,
    PageNameComponent,
    BlankPageCardComponent,
  ],
  exports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSkeletonLoaderModule,
    PageNameComponent,
    BlankPageCardComponent,
    AddButtonComponent,
    LoadingSpinnerComponent,
    PositionPipe,
    ShortNumberPipe,
    ShortNamePipe,
    DateAgoPipe,
    UnitPipe,
    ProgressBarComponent,
    AvatarComponent
  ]
})
export class SharedModule { }
